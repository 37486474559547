import React, { useEffect, useState } from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import kuvsh from "../assets/kuvshinki.png";
import group78 from "../assets/Group 78.png";
import { enUS } from "date-fns/locale";
import locales from "../locales";
import { useTranslation } from "react-i18next";

const LastEventsTable = ({ winEvents, language }) => {
  const [now, setNow] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    // Обновляем 'now' каждую минуту
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 60000); // обновлять раз в минуту

    // Очистка ресурсов при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);
  if (!winEvents) return;

  const renderEventMessage = (event) => {
    switch (event.eventType) {
      case "win":
        return `${t("lastEvents.win")} ${" "} ${
          event.tonValue / 10 ** 9
        }${" "}TON`;
      case "withdraw":
        return t("lastEvents.withdraw");
      case "deposit":
        return t("lastEvents.deposit");
      default:
        return;
    }
  };

  return (
    <div className="flex flex-col relative">
      <img
        src={kuvsh}
        alt="KD"
        className="absolute  top-[-120px] right-[-70px] z-20 hidden lg:block"
      />
      <img
        src={group78}
        alt="g78"
        className="absolute  bottom-[-134px] right-[-106px] hidden lg:block"
      />
      <h1 className="font-lg400 text-acid text-2xl leading-7 mb-3">
        {t("lastEvents.lastEvents")}
      </h1>

      <div className="bg-uf rounded-2xl p-1 z-10 lg:p-8">
        <table className="w-full">
          <thead>
            <tr>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                {t("lastEvents.time")}
              </th>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                {t("lastEvents.wallet")}
              </th>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                {t("lastEvents.event")}
              </th>
            </tr>
          </thead>

          <tbody>
            {winEvents.map((event, index) => (
              <tr key={index}>
                <td className="font-krona text-sm lg:text-lg font-semibold p-1  lg:p-2 lg:leading-4 text-white">
                  {formatDistanceToNow(parseISO(event.eventDatetime), {
                    addSuffix: true,
                    baseDate: now,
                    locale: locales[language]?.fns || enUS,
                  })}
                </td>
                <td className="font-krona p-1 text-sm font-semibold lg:p-2 lg:text-lg lg:leading-4 text-white">
                  {event.userTonAddress.slice(0, 6)}...
                  {event.userTonAddress.slice(-6)}
                </td>
                <td className="font-krona p-1 text-sm lg:text-lg font-semibold lg:p-2 lg:text-l lg:leading-4 text-white">
                  {renderEventMessage(event)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LastEventsTable;
