import React, { useEffect, useState } from "react";
import frogup from "../assets/frog up.png";
import kuvshinkaupleft from "../assets/kuvshinka up left.png";
import frogleft from "../assets/frog left.png";
import {
  parseISO,
  formatDistanceToNowStrict,
  differenceInSeconds,
} from "date-fns";
import { useTonConnectModal } from "@tonconnect/ui-react";
import { setSendModalContent } from "../redux/slices/sendModal/slice";
import locales from "../locales";
import { enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const CheckboxGrid = ({
  handleCheckboxToggle,
  currentRound,
  checkboxes,
  errString = "",
  handleSubmit,
  roundFinishTime,
  dispatch,
  getCurrentRound,
  isDisabled,
  demo,
  tonConnectUI,
  language,
}) => {
  const [timeLeft, setTimeLeft] = useState("");
  const { open } = useTonConnectModal();
  const { t } = useTranslation();

  const onClickButton = async () => {
    //const currentIsConnectedStatus = await tonConnectUI;
    //console.log(currentIsConnectedStatus);
    if (tonConnectUI.connected) {
      //console.log("currentIsConnectedStatus");
      await tonConnectUI.disconnect();
    }
    open();
  };

  const renderCountdown = () => {
    return (
      <div>
        {t("checkbox.roundStarts")}:{" "}
        <span className="text-error font-krona animate-blink">
          {timeLeft || "Loading..."}
        </span>
      </div>
    );
  };
  useEffect(() => {
    let intervalId;
    const updateCountdown = () => {
      if (!roundFinishTime) {
        setTimeLeft("No round end time set");
        return;
      }
      const now = new Date();
      const finishTime = parseISO(roundFinishTime);
      const secondsLeft = differenceInSeconds(finishTime, now);

      if (finishTime > now) {
        const distance = formatDistanceToNowStrict(finishTime, {
          addSuffix: true,
          locale: locales[language]?.fns || enUS,
        });
        setTimeLeft(distance);
        clearInterval(intervalId);
        intervalId = setInterval(
          updateCountdown,
          secondsLeft <= 120 ? 1000 : 10000
        );
      } else {
        dispatch(getCurrentRound());
        setTimeLeft("Round has ended");
        clearInterval(intervalId);
      }
    };

    updateCountdown();

    return () => clearInterval(intervalId);
  }, [roundFinishTime, language]);

  return (
    <>
      <div className="mb-1 lg:mb-3">
        <h1 className="font-lg400 text-acid text-2xl leading-7">
          {t("checkbox.currentRound")}: {currentRound}
        </h1>
      </div>
      <img
        src={frogup}
        alt="Top Right Background"
        className="absolute top-[-45px] right-[-17px] z-20 size-36 lg:top-[-76px] lg:right-[7px] lg:size-auto"
      />
      <img
        src={kuvshinkaupleft}
        alt="KUL"
        className="absolute top-[54px] left-[-156px]"
      />

      <div className=" bg-uf rounded-2xl p-2 z-10 relative lg:p-8">
        <img
          src={frogleft}
          alt="FL"
          className="absolute bottom-[-200px] right-[-60px] z-10 size-72 lg:bottom-[-340px] lg:right-[-140px] lg:size-auto"
        />
        <h2 className="font-krona leading-6 text-base lg:text-xl font-semibold text-white">
          {renderCountdown()}
          {t("checkbox.sixNumbers")}
        </h2>
        <div className="min-h-30">
          <h3 className="font-krona leading-5 text-m text-error lg:leading-6 lg:text-xl">
            {errString}
          </h3>
        </div>
        <div className="flex justify-center">
          <div className="inline-grid grid-cols-9 gap-0 h-fit">
            {checkboxes.map((checked, index) => (
              <button
                key={index}
                // disabled={demo}
                onClick={() => {
                  handleCheckboxToggle(index);
                }}
                className={`w-[40px] h-[40px] lg:w-[67px] lg:h-[68px] lg:min-w-[62px] lg:m-[1px]
              cursor-pointer flex justify-center items-center
            ${
              !checked
                ? "bg-flowerviolet bg-no-repeat bg-cover text-violet"
                : "bg-floweracidpressed bg-no-repeat bg-cover text-violet"
            } `}
              >
                <h2 className="font-krona text-xl font-semibold lg:leading-5 lg:text-xl">
                  {" "}
                  {index + 1}
                </h2>
              </button>
            ))}
          </div>
        </div>
        <div className="flex gap-3">
          <button
            disabled={isDisabled}
            onClick={demo ? onClickButton : handleSubmit}
            className="text-uf mt-10 shadow-outer bg-acid leading-5 text-xl text-center font-lg400 hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-blue-300 font-medium rounded-lg 
                   px-5 py-2.5 mr-2 mb-2 focus:outline-none disabled:shadow-none disabled:bg-pressedviolet disabled:text-uf"
          >
            {t("checkbox.makeBet")}
          </button>
          <button
            disabled={demo}
            onClick={
              demo
                ? onClickButton
                : () =>
                    dispatch(
                      setSendModalContent({
                        isOpn: true,
                      })
                    )
            }
            className="text-uf mt-10 shadow-outer bg-acid leading-5 text-center text-xl font-lg400 hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-blue-300 font-medium rounded-lg 
                   px-5 py-2.5 mr-2 mb-2 focus:outline-none disabled:shadow-none disabled:bg-pressedviolet disabled:text-uf z-20"
          >
            {t("checkbox.deposit")}
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckboxGrid;
