import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/slices/profile/selectors";
import locales from "../locales";

const Rewards = ({ minimalBet = 0, bonuses = {} }) => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const localeString = locales[language]?.localeString || "en-US";
  return (
    <div className="bg-uf rounded-2xl p-2 lg:p-8">
      <div className="font-lg400 text-white leading-7 text-2xl lg:text-3xl">
        {t("rewards.rewards")}
      </div>
      <div className="grid grid-cols-2 gap-4 mt-2 lg:mt-4">
        <div className="flex flex-col items-start justify-center bg-acid rounded-lg p-4">
          <h2 className="font-jost leading-7 text-base lg:text-xl text-pressedtextviolet">
            2 {t("rewards.matches")}
          </h2>
          <h3 className="font-krona leading-6   font-semibold text-xl text-violet whitespace-nowrap">
            {(bonuses[2] / 10 ** 9).toLocaleString(localeString)} TON
          </h3>
        </div>
        <div className="flex flex-col items-start justify-center bg-acid rounded-lg p-4">
          <h2 className="font-jost leading-7  text-base lg:text-xl text-pressedtextviolet">
            3 {t("rewards.matches")}
          </h2>
          <h3 className="font-krona leading-6 text-xl  font-semibold text-violet whitespace-nowrap">
            {(bonuses[3] / 10 ** 9).toLocaleString(localeString)} TON
          </h3>
        </div>
        <div className="flex flex-col items-start justify-center bg-acid rounded-lg p-4">
          <h2 className="font-jost leading-7 text-base lg:text-xl text-pressedtextviolet">
            4 {t("rewards.matches")}
          </h2>
          <h3 className="font-krona leading-6  font-semibold text-xl text-violet whitespace-nowrap">
            {(bonuses[4] / 10 ** 9).toLocaleString(localeString)} TON
          </h3>
        </div>
        <div className="flex flex-col items-start justify-center bg-acid rounded-lg p-4">
          <h2 className="font-jost leading-7 text-base lg:text-xl text-pressedtextviolet">
            5 {t("rewards.matches_five")}
          </h2>
          <h3 className="font-krona leading-6 font-semibold text-xl text-violet whitespace-nowrap">
            {(bonuses[5] / 10 ** 9).toLocaleString(localeString)} TON
          </h3>
        </div>
        <div className="flex flex-col justify-center bg-acid rounded-lg p-4 col-span-2 items-center">
          {" "}
          <h2 className="font-jost leading-7 text-base lg:text-xl text-pressedtextviolet">
            6 {t("rewards.jackpot")}
          </h2>
          <h3 className="font-krona  font-semibold leading-6 text-xl text-violet whitespace-nowrap">
            {Math.floor(bonuses[6] / 10 ** 9).toLocaleString(localeString)} TON
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
