import React from "react";

import kuvshdownmiddle from "../assets/kuvshinka down middle.png";
import kuvshdown from "../assets/kuvshinka down.png";
import kuvshdownleft from "../assets/kuvshinka down left.png";
import { useTranslation } from "react-i18next";

const BetsTable = ({ bets }) => (
  <table className="w-full mt-4">
    <tbody>
      {bets.map((bet, index) => (
        <tr key={index}>
          <td>
            <div className="flex">
              {bet.map((number, numberIndex) => (
                <div
                  key={numberIndex}
                  className="bg-floweracid bg-cover bg-center bg-no-repeat w-[40px] h-[40px] lg:w-[59px] lg:h-[59px] rounded-full flex items-center justify-center mr-2  font-semibold font-krona text-xl lg:text-xl lg:leading-7 text-pressedviolet"
                >
                  {number.bet.toString()}
                </div>
              ))}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const BetsPlaceholder = ({ t }) => (
  <div className="text-center font-krona  font-semibold text-xl text-white mt-4">
    {t("makedBets.noBets")}
  </div>
);

const MakedBets = ({ currentBets }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-uf z-20 rounded-2xl p-6 relative lg:mt-8 lg:p-8">
      <img
        src={kuvshdown}
        alt="KD"
        className="absolute bottom-[-120px] right-[-70px] hidden 2xl:block"
      />
      <img
        src={kuvshdownmiddle}
        alt="KDM"
        className="absolute bottom-[-240px] left-[250px] hidden 2xl:block"
      />
      <img
        src={kuvshdownleft}
        alt="KDM"
        className="absolute bottom-[-140px] left-[120px] hidden 2xl:block"
      />
      <div className="font-krona  font-semibold text-xl text-white">
        {t("makedBets.yourBets")}:
      </div>
      {!currentBets || currentBets.length === 0 ? (
        <BetsPlaceholder t={t} />
      ) : (
        <BetsTable bets={currentBets} />
      )}
    </div>
  );
};

export default MakedBets;
