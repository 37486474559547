import React, { useEffect, useState } from "react";

import CheckboxGrid from "./components/checkboxGrid";
import UserInfo from "./components/userInfo";
import LastEventsTable from "./components/lastEvents";

import MakedBets from "./components/makedBets";
import Rewards from "./components/rewards";
import Basement from "./components/basement";
//import { getUserInfo } from "./api/api";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentRound,
  getLastRounds,
  getUserBets,
  getWinEvents,
} from "./redux/slices/lotto6x45/slice";
import { getUserEvents, getUserInfo } from "./redux/slices/profile/slice";
import {
  selectLanguage,
  selectUserInfo,
} from "./redux/slices/profile/selectors";
import {
  handleCheckboxToggle,
  handleSubmit,
  updateIsDisabled,
} from "./functions/checkboxGridFunctions";
import {
  selectCurrentBets,
  selectCurrentRound,
  selectUserBets,
  selectWinEvents,
} from "./redux/slices/lotto6x45/selectors";
import { selectCheckboxes } from "./redux/slices/checkboxes/selectors";
import { setCheckboxes } from "./redux/slices/checkboxes/slice";
import { useTranslation } from "react-i18next";
import YourBets from "./components/yourBets";

const Lotto6x45 = () => {
  const maxSelectedCheckboxes = 6;
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const currentBets = useSelector(selectCurrentBets);
  const currentRound = useSelector(selectCurrentRound);
  const winEvents = useSelector(selectWinEvents);
  const userBets = useSelector(selectUserBets);
  const checkboxes = useSelector(selectCheckboxes);
  const language = useSelector(selectLanguage);

  const [isDisabled, setIsDisabled] = useState(true);
  const [errString, setErrString] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    updateIsDisabled(
      checkboxes,
      dispatch,
      setIsDisabled,
      maxSelectedCheckboxes,
      errString
    );
    // eslint-disable-next-line
  }, [checkboxes]);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getCurrentRound());
    dispatch(getLastRounds(5));
    dispatch(getWinEvents());
    dispatch(getUserBets());
    dispatch(getUserEvents());

    // Обновление getUserInfo() раз в минуту
    const intervalId = setInterval(() => {
      dispatch(getUserInfo());
      // console.log("refresh");
    }, 60000); // 60000 миллисекунд = 1 минута

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (userInfo?.balance / 10 ** 9 < 1) {
      setErrString(t("checkbox.notEnough"));
    } else {
      setErrString(" ");
    }
  }, [userInfo, language]);

  return (
    <div className="">
      <UserInfo
        userAccount={userInfo?.tonAddress}
        depoBalance={userInfo?.balance / 10 ** 9}
        minimalBet={1}
      />

      <div className="2xl:flex grid flex-wrap m-auto justify-evenly pt-5 gap-4 mx-2 lg:pt-10 lg:gap-20 lg:mx-auto">
        <div className="flex flex-col relative gap-4 overflow-visible 2xl:mb-60 lg:min-w-[733px] lg:w-[733px]">
          <CheckboxGrid
            handleCheckboxToggle={(index) =>
              handleCheckboxToggle(index, checkboxes, setCheckboxes, dispatch)
            }
            checkboxes={checkboxes}
            errString={errString}
            handleSubmit={() => handleSubmit(checkboxes, dispatch, t)}
            currentRound={currentRound.roundNum}
            roundFinishTime={currentRound.finishDatetime}
            dispatch={dispatch}
            getCurrentRound={() => getCurrentRound()}
            isDisabled={isDisabled}
            language={language}
          />
          <MakedBets currentBets={currentBets} />
        </div>
        <div className="flex flex-col relative gap-4  lg:w-[733px]">
          <Rewards minimalBet={1} bonuses={currentRound.bonuses} />
          <YourBets userBets={userBets} language={language} />
          <LastEventsTable winEvents={winEvents} language={language} />
        </div>
      </div>
      <Basement />
    </div>
  );
};

export default Lotto6x45;
