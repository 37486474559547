import "./sendmodal.css";
import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/close.svg";
import { Transition } from "react-transition-group";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { setModalContent } from "../../redux/slices/modal/slice";
import { selectUserInfo } from "../../redux/slices/profile/selectors";
import DepositPage from "./modalPages/deposit";
import { makeWithdraw } from "../../redux/slices/lotto6x45/slice";
import WithdrawPage from "./modalPages/withdraw";
import { useTranslation } from "react-i18next";

const defaultTx = {
  validUntil: Math.floor(Date.now() / 1000) + 600,
  messages: [
    {
      address: process.env.REACT_APP_MAIN_ADDRESS,
      amount: "10000000000",
    },
    {
      address: process.env.REACT_APP_FEE_ADDRESS,
      amount: "100000000",
    },
  ],
};

const SendModal = ({ isOpen, onClose }) => {
  const userInfo = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const [tx, setTx] = useState(defaultTx);
  const [activeTab, setActiveTab] = useState("deposit");
  const [bruttoValue, setBruttoValue] = useState(10.1);
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(false);

  const tonBalance = userInfo?.balance / 10 ** 9;
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch();
  const promoTons = userInfo?.promoTons;
  const [withdrawAmount, setWithdrawAmount] = useState(
    userInfo?.balance / 10 ** 9 - userInfo?.promoTons / 10 ** 9
  );

  if (!userInfo) return null;

  const handleAmountChange = (newAmount) => {
    const newAmountInNano = newAmount * 10 ** 9;
    const feeInNano = newAmountInNano * 0.01;
    const bruttoValueInNano = newAmountInNano + feeInNano;

    setBruttoValue(bruttoValueInNano / 10 ** 9);

    const newTx = {
      ...tx,
      messages: tx.messages.map((message) => {
        if (message.address === process.env.REACT_APP_MAIN_ADDRESS) {
          return { ...message, amount: newAmountInNano.toString() };
        } else if (message.address === process.env.REACT_APP_FEE_ADDRESS) {
          return { ...message, amount: feeInNano.toString() };
        }
        return message;
      }),
    };

    setTx(newTx);
  };

  const handleWithdrawChange = (amount) => {
    const value = Math.max(0, Math.min(amount, userInfo.balance / 10 ** 9));
    const isWD = value < 5;
    setIsWithdrawDisabled(isWD);
    setWithdrawAmount(value);
  };

  const handleSendTransaction = async () => {
    try {
      await tonConnectUI.sendTransaction(tx);
      dispatch(
        setModalContent({
          isOpn: true,
          title: t("modal.transactionSuccess"),
          message: t("modal.waitMinute"),
        })
      );
      onCloseButton();
    } catch (error) {
      dispatch(
        setModalContent({
          isOpn: true,
          title: t("modal.failedSent"),
          message: t("modal.transactionRejected"),
        })
      );
    }
  };

  const handleSendWithdraw = async (amount) => {
    try {
      const response = await dispatch(makeWithdraw(amount * 10 ** 9)).unwrap();
      //console.log("response", response);
      if (response === 200) {
        dispatch(
          setModalContent({
            isOpn: true,
            title: t("modal.transactionSuccess"),
            message: t("modal.yourWithdraw", { amount }),
          })
        );
        onCloseButton();
      } else {
        dispatch(
          setModalContent({
            isOpn: true,
            title: t("modal.failedSent"),
            message: t("modal.transactionRejected"),
          })
        );
      }
    } catch (error) {
      console.log("response", error);
      dispatch(
        setModalContent({
          isOpn: true,
          title: t("modal.failedSent"),
          message: t("modal.transactionRejected"),
        })
      );
    }
  };

  const onCloseButton = () => {
    setActiveTab("deposit");
    onClose();
  };

  return (
    <Transition in={isOpen} timeout={350} unmountOnExit>
      {(state) => (
        <div className={`modal modal--${state}`}>
          <div className="modal-wrapper">
            <div className="modal-content min-h-[250px]">
              <button onClick={onCloseButton} className="modal-close-button">
                <Close />
              </button>
              <div className="flex border-b">
                <button
                  onClick={() => setActiveTab("deposit")}
                  className={`flex-1 py-2 text-xl font-krona font-semibold text-center transition duration-300 ease-in-out ${
                    activeTab === "deposit"
                      ? "border-b-2 border-violet text-violet"
                      : "text-gray-500 hover:text-uf"
                  }`}
                >
                  {t("modal.deposit")}
                </button>
                <button
                  onClick={() => setActiveTab("withdraw")}
                  className={`flex-1 py-2 text-xl font-krona font-semibold text-center transition duration-300 ease-in-out ${
                    activeTab === "withdraw"
                      ? "border-b-2 border-violet text-violet"
                      : "text-gray-500 hover:text-uf"
                  }`}
                >
                  {t("modal.withdraw")}
                </button>
              </div>
              {activeTab === "deposit" && (
                <DepositPage
                  value={tx.messages[0].amount / 10 ** 9}
                  handleAmountChange={handleAmountChange}
                  bruttoValue={bruttoValue}
                  wallet={wallet}
                  dispatch={dispatch}
                  handleSendTransaction={handleSendTransaction}
                  tonConnectUi={tonConnectUI}
                  t={t}
                />
              )}
              {activeTab === "withdraw" && (
                <WithdrawPage
                  tonBalance={tonBalance - promoTons / 10 ** 9}
                  handleWithdrawChange={handleWithdrawChange}
                  withdrawAmount={withdrawAmount}
                  wallet={wallet}
                  tonConnectUI={tonConnectUI}
                  dispatch={dispatch}
                  handleSendWithdraw={handleSendWithdraw}
                  isWithdrawDisabled={isWithdrawDisabled}
                  promoTons={promoTons}
                  t={t}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

export default SendModal;
