import React, { useState } from "react";
import exit from "../assets/exit.png";
import { useTonConnectModal, useTonConnectUI } from "@tonconnect/ui-react";
import { useDispatch } from "react-redux";
import { userDisconnect } from "../api/api";
import { setSendModalContent } from "../redux/slices/sendModal/slice";
import tonSymbol from "../assets/ton_symbol.png";
import tonSymbolViolet from "../assets/ton_symbolviolet.png";
import { useTranslation } from "react-i18next";

const LoginButton = ({ tonConnectUI, t }) => {
  const { open } = useTonConnectModal();

  const onClickButton = async () => {
    if (tonConnectUI.connected) {
      await tonConnectUI.disconnect();
    }
    open();
  };

  return (
    <button
      onClick={() => onClickButton()}
      className="shadow-outer text-violet bg-acid hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-violet font-medium text-m rounded-lg
               px-2 py-2 focus:outline-none lg:px-5 lg:py-[10px]"
    >
      {t("userInfo.pleaseConnect")}
    </button>
  );
};

const DepositeButton = ({ depoBalance }) => {
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(tonSymbolViolet);

  return (
    <button
      onMouseEnter={() => setImageSrc(tonSymbol)}
      onMouseLeave={() => setImageSrc(tonSymbolViolet)}
      onClick={() =>
        dispatch(
          setSendModalContent({
            isOpn: true,
          })
        )
      }
      className="shadow-outer text-violet bg-acid hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-violet font-small text-s rounded-lg
                 px-2 py-1 ml-5 focus:outline-none lg:px-2 lg:py-1] self-center overflow-auto"
    >
      <div className="grid grid-flow-col gap-1">
        {depoBalance ? depoBalance.toFixed(2) : "0.00"}{" "}
        <img
          className="self-center"
          src={imageSrc}
          alt="TON"
          style={{
            width: "20px",
            height: "20px",
            minWidth: "20px",
            minHeight: "20px",
          }}
        />
      </div>
    </button>
  );
};

const UserInfo = ({ depoBalance, userAccount, minimalBet = "XXX" }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [tonConnectUI] = useTonConnectUI();
  return (
    <div className=" bg-uf flex w-full lg:h-[119px]">
      <div className="flex-grow text-left">
        <h1 className="pl-2 pt-2 font-lg400 text-white text-xl lg:pl-[200px] lg:pt-[20px] lg:text-3xl">
          {t("userInfo.title")}
        </h1>

        <div className="pl-2 mb-5 text-white lg:flex lg:pl-[200px] lg:pt-1">
          {userAccount ? (
            <h3 className="font-jost text-s lg:text-xl flex items-center">
              <span className="whitespace-nowrap">
                {t("userInfo.yourBalance")}:
              </span>{" "}
              <DepositeButton depoBalance={depoBalance} />
            </h3>
          ) : (
            <LoginButton tonConnectUI={tonConnectUI} t={t} />
          )}
          <h3 className="font-jost text-s lg:text-xl lg:pl-5 flex items-center pt-1">
            <div className="grid grid-flow-col gap-1">
              {t("userInfo.bet")}: {minimalBet}{" "}
              <img
                src={tonSymbol}
                alt="TON"
                style={{ width: "20px", height: "20px" }}
                className="self-center"
              />
            </div>
          </h3>
        </div>
      </div>
      <div className="flex-grow flex text-right pr-2 pt-2 items-start lg:pr-[200px] lg:pt-[20px] lg:justify-end">
        {userAccount ? (
          <div className="flex-grow justify-between ">
            <div
              onClick={() => userDisconnect(tonConnectUI, dispatch)}
              className=" font-jost text-white leading-7 font-medium text-xl cursor-pointer flex gap-2 justify-end items-center"
            >
              {t("userInfo.logout")}
              <img
                src={exit}
                alt="exit"
                className="cursor-pointer"
                style={{ height: "20px", width: "20px" }}
              />
            </div>
            <div className="font-jost mt-3 text-xl text-white">
              {t("userInfo.wallet")}: {userAccount.slice(0, 6)}...
              {userAccount.slice(-6)}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
