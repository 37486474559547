import React, { useEffect, useState } from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import frogright from "../assets/frog right.png";
import { useTranslation } from "react-i18next";
import locales from "../locales";
import { enUS } from "date-fns/locale";

const YourBets = ({ userBets = {}, language }) => {
  const hasEvents = userBets && userBets.length > 0;
  const { t } = useTranslation();

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    // Обновляем 'now' каждую минуту
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 60000); // обновлять раз в минуту

    // Очистка ресурсов при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);

  const getBG = (isTrue) => {
    return isTrue ? "bg-floweracid" : "bg-flowerviolet";
  };
  return (
    <div className="flex flex-col relative">
      <img
        src={frogright}
        alt="FR"
        className="absolute top-[-158px] right-[-110px] w-[368px] h-[450px] hidden lg:block z-40"
      />
      <h1 className="font-lg400 text-acid text-2xl leading-7 mb-3">
        {t("yourBets.yourBets")}
      </h1>
      <div className="bg-uf rounded-2xl p-2 lg:p-8 min-h-[200px] z-30">
        {hasEvents ? (
          <table className="w-full ">
            <thead>
              <tr>
                <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                  {t("yourBets.time")}
                </th>
                <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                  {t("yourBets.roundNo")}
                </th>
                <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                  {t("yourBets.numbers")}
                </th>
              </tr>
            </thead>
            <tbody>
              {userBets.map((event, index) => (
                <tr key={index}>
                  <td className="font-krona  font-semibold p-1 text-sm lg:text-xl text-white lg:p-2 lg:text-l lg:leading-4 whitespace-normal">
                    {formatDistanceToNow(parseISO(event.createdAt), {
                      addSuffix: true,
                      baseDate: now,
                      locale: locales[language]?.fns || enUS,
                    })}
                  </td>
                  <td className="font-krona font-semibold  p-1 text-base lg:text-xl text-white lg:p-2 lg:text-l lg:leading-4">
                    {event.roundNumber}{" "}
                  </td>
                  <td className="font-krona font-semibold p-1 text-xl text-white lg:p-2 lg:text-l lg:leading-4">
                    <div className="grid grid-cols-3 lg:flex">
                      {event.myBet.map((number, numberIndex) => (
                        <div
                          key={numberIndex}
                          className={`${getBG(number.win)}
                        w-[20px] h-[20px] lg:w-[47px] lg:h-[48px] bg-cover rounded-full flex items-center justify-center mr-2 font-jost text-xs lg:text-xl leading-7 text-pressedviolet               
                                            `}
                        >
                          {number.bet.toString()}
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-white font-krona font-semibold text-xl p-2 text-center">
            {t("yourBets.noBets")}
          </div>
        )}
      </div>
    </div>
  );
};

export default YourBets;
