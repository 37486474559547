import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

const initialState = {
  currentRound: [],
  lastRounds: [],
  winEvents: [],
  userBets: [],
  currentBets: [],
};

export const getCurrentRound = createAsyncThunk(
  "lotto6x45/getCurrentRound",
  async () => {
    try {
      const response = await instance.get("rounds/current");
      return response.data;
    } catch (error) {
      //console.error("Error getCurrentRound:", error);
    }
  }
);

export const getLastRounds = createAsyncThunk(
  "lotto6x45/getLastRounds",
  async (n) => {
    try {
      let url = "rounds";

      if (n !== undefined) {
        url += `?last_n=${n}`;
      }

      const response = await instance.get(url);
      return response.data;
    } catch (error) {
      //console.error("Error getLastRounds:", error);
    }
  }
);

export const getWinEvents = createAsyncThunk(
  "lotto6x45/getWinEvents",
  async () => {
    try {
      const response = await instance.get("rounds/win-events?last_n=5");
      return response.data;
    } catch (error) {
      //console.error("Error getWinEvents:", error);
    }
  }
);

export const getUserBets = createAsyncThunk(
  "lotto6x45/getUserBets",
  async () => {
    try {
      const response = await instance.get("user-bets");
      return response.data;
    } catch (error) {
      //console.error("Error getUserBets:", error);
    }
  }
);

export const makeBet = createAsyncThunk("lotto6x45/makeBet", async (bets) => {
  try {
    await instance.post("user-bets", bets);
  } catch (error) {
    //console.error("Error makeBet:", error);
  }
});

export const makeWithdraw = createAsyncThunk(
  "lotto6x45/makeWithdraw",
  async (amount, { rejectWithValue }) => {
    console.log("amount", amount);
    try {
      const response = await instance.post("profile/withdraw-ton", {
        amount: amount, // Оборачиваем значение amount в объект
      });
      return response.status; // Возвращаем статус в случае успеха
    } catch (error) {
      if (error.response) {
        // Сервер ответил с кодом ошибки
        return rejectWithValue(error.response.status); // Возвращаем статус ошибки
      } else if (error.request) {
        // Запрос был сделан, но ответа не получено
        return rejectWithValue("No response received");
      } else {
        // Произошло что-то при настройке запроса
        return rejectWithValue("Request error");
      }
    }
  }
);

export const lotto6x45Slice = createSlice({
  name: "lotto6x45",
  initialState,
  reducers: {
    setRoundResult(state, action) {
      state.roundResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentRound.fulfilled, (state, action) => {
        state.currentRound = action.payload;
        state.currentBets = state.userBets
          ?.filter((bet) => bet.roundNumber === state.currentRound.roundNum)
          .map((bets) => bets.myBet); // Извлекаем только массив bet
      })
      .addCase(getLastRounds.fulfilled, (state, action) => {
        state.lastRounds = action.payload;
      })
      .addCase(makeWithdraw.fulfilled, (state, action) => {
        state.status = action.payload; // Сохраняем статус успеха
        state.error = null;
      })
      .addCase(makeWithdraw.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload; // Сохраняем статус ошибки
      })
      .addCase(getUserBets.fulfilled, (state, action) => {
        state.userBets = action.payload;
        state.currentBets = state.userBets
          ?.filter((bet) => bet.roundNumber === state.currentRound.roundNum)
          .map((bets) => bets.myBet); // Извлекаем только массив bet
      })
      .addCase(getWinEvents.fulfilled, (state, action) => {
        state.winEvents = action.payload;
      });
  },
});
// .addCase(takeReward.fulfilled, (state, action) => {
//     state.takeReward = true;
// },)

export const { setCurrentRound } = lotto6x45Slice.actions;
export default lotto6x45Slice.reducer;
