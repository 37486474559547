import React from "react";
import MainPage from "./mainPage";
import toprightbg from "./assets/toprightbg.png";
import downleftbg from "./assets/downleftbg.png";
import downrightbg from "./assets/downrightbg.png";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { Suspense } from "react";
import { useDispatch } from "react-redux";
import locales from "./locales";
import { setLanguage } from "./redux/slices/profile/slice";

function App() {
  const dispatch = useDispatch();
  const browserLocale = navigator.language.split("-")[0]; // Например, 'en-US' станет 'en'
  const initialLocale = locales[browserLocale] ? browserLocale : "en";
  dispatch(setLanguage(initialLocale));
  return (
    <Suspense fallback="...loading">
      <TonConnectUIProvider manifestUrl="https://dev.ton6x45.com/tonmanifest.json">
        <div
          className="min-h-screen w-full min-w-[358px] overflow-hidden"
          style={{
            background: `url(${toprightbg}),url(${downleftbg}),url(${downrightbg})`,
            backgroundColor: "#130D1A",
            backgroundPosition:
              "right -289px top -276px,left -492px bottom -357px,right -439px bottom -449px ",
            backgroundRepeat: "no-repeat, no-repeat,no-repeat",
            backgroundSize: "1047px 1048px,1458px 1458px, 1408px 1410px",
            backgroundAttachment: "fixed,fixed,fixed",
          }}
        >
          <MainPage />
        </div>
      </TonConnectUIProvider>
    </Suspense>
  );
}

export default App;
