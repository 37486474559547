import { enUS, de, fr, ru, zhCN } from "date-fns/locale";

const locales = {
  en: { title: "English", fns: enUS, localeString: "en-US" },
  de: { title: "Deutsch", fns: de, localeString: "de-DE" },
  fr: { title: "Français", fns: fr, localeString: "fr-FR" },
  ru: { title: "Русский", fns: ru, localeString: "ru-RU" },
  zhCN: { title: "繁體中文", fns: zhCN, localeString: "zh-CN" },
};

export default locales;
