import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import i18n from "../../../i18n";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

const initialState = {
  userInfo: [],
  userEvents: [],
  language: "",
};

export const getUserInfo = createAsyncThunk(
  "lotto6x45/getUserInfo",
  async () => {
    try {
      const response = await instance.get("profile/me");
      return response.data;
    } catch (error) {
      // console.error("Error getUserInfo:", error);
    }
  }
);

export const getUserEvents = createAsyncThunk(
  "lotto6x45/getUserEvents",
  async () => {
    try {
      const response = await instance.get("profile/user-events");
      return response.data;
    } catch (error) {
      //console.error("Error getUserEvents:", error);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearProfile(state) {
      state.userInfo = [];
      state.userEvents = [];
    },
    setLanguage(state, action) {
      state.language = action.payload;
      i18n.changeLanguage(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(getUserEvents.fulfilled, (state, action) => {
        state.userEvents = action.payload;
      });
  },
});

export const { clearProfile, setLanguage } = profileSlice.actions;
export default profileSlice.reducer;
