const DepositPage = ({
  value,
  handleAmountChange,
  bruttoValue,
  wallet,
  dispatch,
  handleSendTransaction,
  tonConnectUi,
  t,
}) => {
  return (
    <div className="send-tx-form">
      <div className="input-group">
        <label htmlFor="myInput font-krona font-bold">
          {t("modal.add")} TON
        </label>
        <input
          type="number"
          id="amount"
          value={value}
          min="0"
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <p className="input-hint font-krona font-medium">
          *{bruttoValue.toFixed(2)} TON {t("modal.charged")}
        </p>
      </div>
      {wallet ? (
        <button
          disabled={bruttoValue === 0}
          onClick={() => handleSendTransaction(dispatch)}
          className="shadow-outer text-violet bg-acid font-krona font-semibold hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-violet text-m rounded-lg
                    px-2 py-2 focus:outline-none lg:px-5 lg:py-[10px] disabled:shadow-none disabled:bg-pressedviolet disabled:text-uf"
        >
          {t("modal.transfer")}
        </button>
      ) : (
        <button onClick={() => tonConnectUi.openModal()}>
          Connect wallet to send the transaction
        </button>
      )}
    </div>
  );
};

export default DepositPage;
