import React, { useEffect, useRef, useState } from "react";
import frogsit from "../assets/frog sit.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { setLanguage } from "../redux/slices/profile/slice";
import { useDispatch, useSelector } from "react-redux";
import locales from "../locales";
import { selectLanguage } from "../redux/slices/profile/selectors";

function ChooseLanguage({ t }) {
  const sortRef = useRef(null);
  const dispatch = useDispatch();
  const selectedLocale = useSelector(selectLanguage);

  const [isVisible, setIsVisible] = useState(false);

  const onClickItems = (v) => {
    dispatch(setLanguage(v));
    setIsVisible(false);
    i18n.changeLanguage(v);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const _event = event;

      if (
        _event.path &&
        Array.isArray(_event.path) &&
        sortRef.current &&
        !_event.path.includes(sortRef.current)
      ) {
        setIsVisible(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);
  return (
    <div
      ref={sortRef}
      className="sort relative font-jost text-white text-s lg:text-2xl "
    >
      <div className="sort__label text-white flex items-center">
        {isVisible && (
          <div className="sort__popup absolute items-center  bg-pressedviolet text-white mt-4 right-0 -top-[240px] rounded-lg shadow-2xl min-w-[150px] z-50">
            <ul className="px-3 py-3 ">
              {Object.keys(locales).map((locale) => {
                return (
                  <li
                    className={`cursor-pointer hover:bg-uf py-1 font-jost  text-s lg:text-2xl ${
                      locale === selectedLocale
                        ? "font-bold text-acid"
                        : "text-white"
                    }`}
                    key={locale}
                    onClick={() => onClickItems(locale)}
                  >
                    {locales[locale].title}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <span
          className=" flex gap-2 cursor-pointer"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          {t("basement.language")}

          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 5C10 5.16927 9.93815 5.31576 9.81445 5.43945C9.69075 5.56315 9.54427 5.625 9.375 5.625H0.625C0.455729 5.625 0.309245 5.56315 0.185547 5.43945C0.061849 5.31576 0 5.16927 0 5C0 4.83073 0.061849 4.68424 0.185547 4.56055L4.56055 0.185547C4.68424 0.061849 4.83073 0 5 0C5.16927 0 5.31576 0.061849 5.43945 0.185547L9.81445 4.56055C9.93815 4.68424 10 4.83073 10 5Z"
              fill="#FFFFFF"
            />
          </svg>
        </span>
        {/* <span
          className=" flex gap-2"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          {locales[selectedLocale].title}


        </span> */}
      </div>
    </div>
  );
}

const Basement = () => {
  const { t } = useTranslation();
  return (
    <div className=" bg-background mt-32 relative pb-2 lg:h-[119px]">
      <img
        src={frogsit}
        alt="KUL"
        className="absolute top-[-130px] left-[-10px] z-10 size-36 lg:size-auto lg:top-[-240px] lg:left-[-50px]"
      />
      <div className="flex gap-2 items-center pt-2 lg:pt-[34px]">
        <h1 className="pl-2 font-lg400 text-white text-s text-center items-center lg:pl-[200px] lg:text-3xl">
          {t("userInfo.title")}
        </h1>

        <div className="flex gap-5 flex-grow items-end text-right pr-2 justify-end lg:pr-[200px]">
          <ChooseLanguage t={t} />{" "}
          <p className="font-jost text-white text-s lg:text-2xl">/</p>
          <a
            href="mailto:blocktechkg@gmail.com"
            title="Feedback"
            target="_blank"
            rel="noopener noreferrer"
            className="self-center"
          >
            <h1 className="font-jost text-white text-s lg:text-2xl ">
              {t("basement.support")}
            </h1>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Basement;
